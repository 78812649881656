import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaFacebook, FaTwitter, FaYoutube, FaLinkedin } from 'react-icons/fa';
import './Footer.css'; // Custom styling if needed

const Footer = () => {
  return (
    <footer className="footer bg-dark text-light py-3">
      <Container>
        {/* Logo Section */}
        {/* <Row className="justify-content-center mb-4">
          <Col className="text-center">
            <img src="logo512.png" alt="Logistics Logo" className="footer-logo mb-3" />
          </Col>
        </Row> */}

        {/* Social Media Icons */}
        <Row className="justify-content-center mb-4">
          <Col xs="auto">
            <a href="https://facebook.com" className="social-icon" target="_blank" rel="noopener noreferrer">
              <FaFacebook size={30} />
            </a>
          </Col>
          <Col xs="auto">
            <a href="https://twitter.com" className="social-icon" target="_blank" rel="noopener noreferrer">
              <FaTwitter size={30} />
            </a>
          </Col>
          <Col xs="auto">
            <a href="https://youtube.com" className="social-icon" target="_blank" rel="noopener noreferrer">
              <FaYoutube size={30} />
            </a>
          </Col>
          <Col xs="auto">
            <a href="https://linkedin.com" className="social-icon" target="_blank" rel="noopener noreferrer">
              <FaLinkedin size={30} />
            </a>
          </Col>
        </Row>

        {/* Navigation Links */}
        <Row className="justify-content-center mb-4">
          <Col xs="auto">
            <Link className="footer-link" to="/">HOME</Link>
          </Col>
          <Col xs="auto">
          <Link className="footer-link" to="/about">ABOUT US</Link>
          </Col>
          <Col xs="auto">
          <Link className="footer-link" to="/blog">BLOG</Link>
          </Col>
          <Col xs="auto">
          <Link className="footer-link" to="/career">CAREER</Link>
          </Col>
          <Col xs="auto">
          <Link className="footer-link" to="/contact">CONTACT</Link>
          </Col>
          <Col xs="auto">
          <Link className="footer-link" to="/privacy-policy">PRIVACY POLICY</Link>
          </Col>
        </Row>

        {/* Copyright Section */}
        <Row className="justify-content-center">
          <Col className="text-center">
            <p>©2024 Arsen Valeev website creted for educational point</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
