import React from 'react'
import './ServicesCards.css'
import { Card, Row, Col, Container } from 'react-bootstrap';

const ServicesCards = () => {
    return (
        <div className='ServicesCards'>
            <Container className="py-4">
                <Row className="g-4">
                    <Col md={4}>
                        <Card className="h-100">
                            <Card.Img variant="top" src="./assets/ServingCardOne.webp" />
                            <Card.Body>
                                <Card.Title className='title'>BLOGS AND INSIGHTS</Card.Title>
                                <Card.Text className='text'>
                                    Explore a range of insightful blogs on our website, covering various topics that provide in-depth information and valuable knowledge for visitors.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card className="h-100">
                            <Card.Img variant="top" src="./assets/ServingCardTwo.webp" />
                            <Card.Body>
                                <Card.Title className='title'>ABOUT OUR JOURNEY</Card.Title>
                                <Card.Text className='text'>
                                    Discover the story behind our website and the purpose driving us, along with detailed information about our goals, values, and mission.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card className="h-100">
                            <Card.Img variant="top" src="./assets/ServingCardThree.webp" />
                            <Card.Body>
                                <Card.Title className='title'>GALLERY AND CONTACT</Card.Title>
                                <Card.Text className='text'>
                                    Visit the gallery to view our images, and through the contact form, you can easily send us a message for any inquiries or feedback.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

            </Container>
        </div>
    );
};

export default ServicesCards;
