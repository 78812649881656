import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { Gallery } from '../../components';
import './About.css';

const About = () => {
  return (
    <>
      {/* Hero Section with Background Image */}
      <div className="about-hero">
        <div className="hero-text">
          <h1>ABOUT US</h1>
        </div>
      </div>

      {/* Text Content Section */}
      <Container className="my-5">
        <Row>
          <Col>
            <h2 className="text-left">COMMITTED TO HANDLING YOUR LOGISTICS NEEDS WITH PRECISION.</h2>
            <p>
              CargoWave started in 2005 in Seattle, Washington, as a small logistics startup. Though we began with a modest team, CargoWave has since expanded into one of the leading logistics providers across North America, with a network of over 50 locations and a reputation for tackling complex logistical challenges. CargoWave delivers top-tier service through a regional presence. Our offerings include full-service freight handling, custom packaging, supply chain optimization, time-sensitive deliveries, secure warehousing, and project management for large-scale shipments, all designed to ensure clients benefit from the most cost-effective solutions. CargoWave also prides itself on having a robust technology team, driving innovation in all aspects of logistics management.
            </p>

          </Col>
        </Row>
      </Container>
      <Gallery />
    </>
  );
};

export default About;
