// src/components/MailSend.js
import React, { useState } from 'react';
import { Form, Button, Container } from 'react-bootstrap';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebaseConfig'; // Firebase Firestore config
import emailjs from 'emailjs-com';
import './MailSend.css';

const MailSend = () => {
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [sending, setSending] = useState(false);

  // Fetch all subscribers' emails from Firestore
  const fetchSubscribers = async () => {
    const subscribersSnapshot = await getDocs(collection(db, 'subscribers'));
    const subscribersList = subscribersSnapshot.docs.map(doc => doc.data().email);
    return subscribersList;
  };

  // Handle form submission and send emails
  const handleSendEmails = async (e) => {
    e.preventDefault();
    setSending(true);

    try {
      // Fetch all subscribers' emails
      const subscribers = await fetchSubscribers();

      // Prepare email params for EmailJS
      const emailParams = {
        blogTitle: subject,
        blogUrl: message,  // Here we're using "message" to represent the blog URL
      };

      // Send an email to each subscriber using EmailJS
      subscribers.forEach(subscriberEmail => {
        emailjs.send(
          'service_fsc1sf6', // Replace with your EmailJS service ID
          'template_mvnp1t1', // Replace with your EmailJS template ID
          {
            ...emailParams,
            to_email: subscriberEmail, // Send to each subscriber's email
          },
          'hXzdWueuVr7ui9W5X' // Replace with your EmailJS user ID (public key)
        )
        .then(response => {
          console.log('Email sent to:', subscriberEmail, response.status, response.text);
        })
        .catch(error => {
          console.error('Failed to send email to:', subscriberEmail, error);
        });
      });

      alert('Emails sent successfully!');
    } catch (error) {
      console.error('Failed to send emails:', error);
      alert('Failed to send emails. Please try again later.');
    } finally {
      setSending(false);
    }
  };

  // Handle downloading the CSV file with all subscriber emails
  const handleDownloadEmails = async () => {
    try {
      const subscribers = await fetchSubscribers();
      const csvContent = subscribers.join('\n');
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'subscribers.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Failed to download subscriber emails:', error);
      alert('Failed to download subscriber emails. Please try again later.');
    }
  };

  return (
    <Container className="mail-send-container">
      <h2>Send Email to All Subscribers</h2>

      <Form onSubmit={handleSendEmails}>
        <Form.Group controlId="formSubject" className="mb-3">
          <Form.Label>Blog Title</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter blog title"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="formMessage" className="mb-3">
          <Form.Label>Blog URL</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter blog URL"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          />
        </Form.Group>

        <Button variant="warning" type="submit" disabled={sending} style={{ borderRadius: 0, width: '100%' }}>
          {sending ? 'Sending...' : 'Send Emails'}
        </Button>
      </Form>

      <Button
        variant="warning"
        className="mt-3"
        onClick={handleDownloadEmails}
        style={{ borderRadius: 0 }}
      >
        Download Subscriber Emails
      </Button>
    </Container>
  );
};

export default MailSend;
