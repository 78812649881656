import React from 'react'
import { Button, Container } from 'react-bootstrap';
import './PanelAbout.css'
import { useNavigate } from 'react-router-dom';

const PanelAbout = () => {
  const navigate = useNavigate();

  return (
    <div className="hero-section">
      <Container className="text-center text-white d-flex flex-column justify-content-center align-items-center h-100">
        <h1 className="display-3 font-weight-bold">HOW CAN WE HELP?</h1>
        <p className="lead">
          OUR TEAM IS READY TO ASSIST YOU IN YOUR NEXT LIFT PROJECT. Schedule a discovery call with our experts today!
        </p>
        <Button 
          variant="outline-warning" 
          size="lg" 
          className="mt-3" 
          onClick={() => {
            console.log("Navigating to Contact Page");
            navigate('/Contact');
          }}
        >
          CONTACT
        </Button>
      </Container>
    </div>
  );
};

export default PanelAbout
