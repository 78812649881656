// src/components/BannerBlock.js
import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import './BannerBlock.css';

const BannerBlock = () => {
  return (
    <Container fluid className="p-0 banner-block">
      <Row className="g-0 align-items-center">
        {/* Left Image */}
        <Col md={6} className="p-0">
          <Image
            src="https://cdn.pixabay.com/photo/2024/08/14/14/52/ai-generated-8968720_1280.jpg"
            alt="Heavy Equipment"
            fluid
            className="banner-image"
          />
        </Col>

        {/* Right Text Block */}
        <Col md={6} className="text-block p-4 p-md-5">
          <h1 className="display-4">THIS WEBSITE CRETED AS EXAMPLE</h1>
          <p>
            This website is created for educational purposes, featuring a combination 
            of AI-generated images and visuals sourced from Pixabay. The aim is to provide 
            a rich and diverse visual experience, highlighting various concepts and ideas 
            in an engaging way.
          </p>
          <h5 className="mt-4">Your success is our mission;</h5>
        </Col>
      </Row>
    </Container>
  );
};

export default BannerBlock;
