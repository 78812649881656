// src/components/CareerLink.js
import React from 'react';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import './CareerLink.css';

const CareerLink = () => {
  return (
    <Container fluid className="p-0 career-link-section">
      <Row className="g-0 align-items-center">
        {/* Left Image */}
        <Col md={6} className="p-0">
          <Image
            src="https://cdn.pixabay.com/photo/2024/08/14/14/52/ai-generated-8968720_1280.jpg"
            alt="Team Member"
            fluid
            className="career-image"
          />
        </Col>

        {/* Right Text Block */}
        <Col md={6} className="career-text-block p-4 p-md-5">
          <h1 className="career-title">JOIN CARGOWAVE AND BUILD YOUR FUTURE</h1>
          <p className="career-description">
            At CargoWave, you’re more than just an employee—you’re a vital part of a company that’s rapidly expanding and making waves in the logistics industry. With locations across the country, CargoWave has earned a reputation for excellence, and we’re looking for dedicated individuals who are ready to make a real difference. If you’re a high achiever seeking more than just a job, come discover the opportunities waiting for you at CargoWave.
          </p>

          <Button
            variant="outline-warning"
            className="explore-button"
            href="https://www.linkedin.com/"
          >
            LinkedIn
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default CareerLink;
