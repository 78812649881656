// src/CreateBlog.js
import React, { useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { collection, addDoc } from 'firebase/firestore';
import { storage, db } from '../../firebaseConfig'; 

const CreateBlog = () => {
  const [title, setTitle] = useState('');
  const [author, setAuthor] = useState('');
  const [date, setDate] = useState('');
  const [content, setContent] = useState('');
  const [image, setImage] = useState(null);
  const [uploading, setUploading] = useState(false);

  const handleImageUpload = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUploading(true);

    try {
      let imageUrl = '';
      
      // Upload image to Firebase storage if there is an image
      if (image) {
        const imageRef = ref(storage, `images/${image.name}`);
        await uploadBytes(imageRef, image);
        imageUrl = await getDownloadURL(imageRef);
      }

      // Blog post data
      const blogPost = {
        title,
        author,
        date,
        imageUrl,
        content
      };

      // Add blog post to Firestore
      await addDoc(collection(db, 'blogs'), blogPost);

      alert('Blog post created successfully!');
      // Reset form after submission
      setTitle('');
      setAuthor('');
      setDate('');
      setContent('');
      setImage(null);
      setUploading(false);
    } catch (error) {
      console.error('Error creating blog post:', error);
      alert('Failed to create blog post.');
      setUploading(false);
    }
  };

  return (
    <Container className="mt-5">
      <h1 className="text-center mb-4">Create Blog Post</h1>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formTitle" className="mb-3">
          <Form.Label>Title</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter blog title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
            style={{ borderRadius: 0 }}
          />
        </Form.Group>

        <Form.Group controlId="formAuthor" className="mb-3">
          <Form.Label>Author</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter author name"
            value={author}
            onChange={(e) => setAuthor(e.target.value)}
            required
            style={{ borderRadius: 0 }}
          />
        </Form.Group>

        <Form.Group controlId="formDate" className="mb-3">
          <Form.Label>Date</Form.Label>
          <Form.Control
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            required
            style={{ borderRadius: 0 }}
          />
        </Form.Group>

        <Form.Group controlId="formContent" className="mb-3">
          <Form.Label>Content</Form.Label>
          <Form.Control
            as="textarea"
            rows={6}
            placeholder="Enter blog content"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            required
            style={{ borderRadius: 0 }}
          />
        </Form.Group>

        <Form.Group controlId="formImage" className="mb-3">
          <Form.Label>Image</Form.Label>
          <Form.Control
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            required
            style={{ borderRadius: 0 }}
          />
        </Form.Group>

        <Button
          variant="warning"
          type="submit"
          style={{ borderRadius: 0, width: '100%' }}
          disabled={uploading}
        >
          {uploading ? 'Uploading...' : 'Submit'}
        </Button>
      </Form>
    </Container>
  );
};

export default CreateBlog;
