// src/components/CareerAbout.js
import React from 'react';
import { Container, Button } from 'react-bootstrap';
import './CareerAbout.css';

const CareerAbout = () => {
  return (
    <div className="career-about-section">
      <div className="overlay">
        <Container className="text-center text-container">
          <h1 className="hashtag-title">#LIFEATCARGOWAVE</h1>
          <p className="about-description">
            CargoWave’s approach to our most valuable asset—our people—starts with the belief that every individual deserves respect and appreciation. We also value the importance of dedication and the rewards that come from hard work. These core principles guide our approach to recruiting, training, and supporting our team members.
          </p>

          <Button variant="warning" className="explore-button" href="/about" style={{ borderRadius: 0 }}>
            EXPLORE OUR CULTURE
          </Button>
        </Container>
      </div>
    </div>
  );
};

export default CareerAbout;
