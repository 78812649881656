import React from 'react';
import './VideoPlayer.css';

const VideoPlayer = () => {
  return (
    <div className="video-container">
      <iframe 
        width="100%" 
        height="100%" 
        src="https://www.youtube.com/embed/MMyZ9Pu01RI?si=L1shAwKCpK_NPP0_" 
        title="YouTube video player" 
        frameBorder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
        allowFullScreen
        className="video-player"
      ></iframe>
    </div>
  );
}

export default VideoPlayer;
