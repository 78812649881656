import React from 'react';
import { Carousel, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './CarouselsAbout.css'; // Assuming you add custom CSS for shadows

const CarouselsAbout = () => {
  
  const navigate = useNavigate();

  return (
    <Carousel>
      <Carousel.Item>
        <div className="slider-image-container shadow-lg">
          <img
            className="d-block w-100"
            src="https://firebasestorage.googleapis.com/v0/b/milestone-logistics-5e067.appspot.com/o/images%2Fcontainers-8676552_1280%201.png?alt=media&token=550ef2e7-1ddc-47bf-9be1-ff9168b424d3" // Replace with another image
            alt="Second slide"
          />
        </div>
        <Carousel.Caption>
          <h3>CargoWave GROUP</h3>
          {/* <p></p> */}
          <Button variant="outline-warning" className="mt-3" style={{fontSize: 23}} onClick={() => navigate('/about')}>ABOUT US</Button>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <div className="slider-image-container shadow-lg">
          <img
            className="d-block w-100"
            src="https://firebasestorage.googleapis.com/v0/b/milestone-logistics-5e067.appspot.com/o/images%2Fshutterstock_1018477507-scaled%201.png?alt=media&token=cdd241cb-d5f3-4aca-924a-2c50e943762d" // Replace with your image
            alt="First slide"
          />
        </div>
        <Carousel.Caption>
          <h3>OUR BLOG</h3>
          {/* <p>CRANE+RIGGING</p> */}
          <Button variant="outline-warning" className="mt-3" style={{fontSize: 23}} onClick={() => navigate('/blog')}>BLOG</Button>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <div className="slider-image-container shadow-lg">
          <img
            className="d-block w-100"
            src="https://cdn.pixabay.com/photo/2024/07/13/18/24/airplane-8892881_1280.jpg" // Replace with another image
            alt="Second slide"
          />
        </div>
        <Carousel.Caption>
          <h3>More than company</h3>
          {/* <p>Some additional text for slide two.</p> */}
          <Button variant="outline-warning" className="mt-3" style={{fontSize: 23}} onClick={() => navigate('/career')}>Career</Button>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default CarouselsAbout;
