// src/BlogCard.js
import React from 'react';
import { Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './BlogCard.css'; // For additional styling if needed

const BlogCard = ({ id, title, date, excerpt }) => {
  return (
    <Card className="mb-4 blog-card h-100" style={{ borderRadius: 0 }}>
      <Card.Body>
        <Card.Text className="text-muted blog-card-date">{date}</Card.Text>
        <Card.Title className="blog-card-title">{title}</Card.Title>
        <Card.Text className="blog-card-excerpt">{excerpt}</Card.Text>
        <Link to={`/blog-open-card/${id}`}>
          <Button variant="outline-warning" className="blog-card-btn" style={{ borderRadius: 0 }}>
            Read More...
          </Button>
        </Link>
      </Card.Body>
    </Card>
  );
};

export default BlogCard;
