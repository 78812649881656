// src/DeleteBlog.js
import React, { useEffect, useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { collection, getDocs, deleteDoc, doc, getDoc } from 'firebase/firestore';
import { ref, deleteObject } from 'firebase/storage';
import { db, storage } from '../../firebaseConfig'; // Import Firestore and Storage references

const DeleteBlog = () => {
  const [blogs, setBlogs] = useState([]);
  const [selectedBlogId, setSelectedBlogId] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  // Fetch blogs from Firestore
  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const blogsCollection = collection(db, 'blogs');
        const blogsSnapshot = await getDocs(blogsCollection);
        const blogsList = blogsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setBlogs(blogsList);
      } catch (error) {
        console.error('Error fetching blogs:', error);
        setError('Failed to fetch blogs.');
      }
    };

    fetchBlogs();
  }, []);

  // Delete the blog and the associated image
  const handleDelete = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      // Retrieve the selected blog data
      const blogDocRef = doc(db, 'blogs', selectedBlogId);
      const blogDoc = await getDoc(blogDocRef);

      if (blogDoc.exists()) {
        const blogData = blogDoc.data();
        const imageUrl = blogData.imageUrl; // Get the imageUrl from the blog document

        // If the blog has an image, delete it from Firebase Storage
        if (imageUrl) {
          const imageRef = ref(storage, imageUrl); // Create a reference to the image in storage
          await deleteObject(imageRef);
        }

        // Delete the blog document from Firestore
        await deleteDoc(blogDocRef);

        // Remove the blog from local state
        setBlogs(blogs.filter((blog) => blog.id !== selectedBlogId));
        setSelectedBlogId('');
        alert('Blog and associated image deleted successfully!');
      } else {
        setError('Blog post does not exist.');
      }
    } catch (error) {
      console.error('Error deleting blog or image:', error);
      setError('Failed to delete blog or image.');
    }

    setLoading(false);
  };

  return (
    <Container className="mt-5">
      <h1 className="text-center mb-4">Delete Blog Post</h1>
      {error && <p className="text-danger text-center">{error}</p>}
      <Form onSubmit={handleDelete}>
        <Form.Group controlId="formBlogSelect" className="mb-3">
          <Form.Label>Select Blog to Delete</Form.Label>
          <Form.Control
            as="select"
            value={selectedBlogId}
            onChange={(e) => setSelectedBlogId(e.target.value)}
            required
            style={{ borderRadius: 0 }}
          >
            <option value="">-- Select a Blog --</option>
            {blogs.map((blog) => (
              <option key={blog.id} value={blog.id}>
                {blog.title} by {blog.author} on {blog.date}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        <Button
          variant="danger"
          type="submit"
          style={{ borderRadius: 0, width: '100%' }}
          disabled={!selectedBlogId || loading}
        >
          {loading ? 'Deleting...' : 'Delete'}
        </Button>
      </Form>
    </Container>
  );
};

export default DeleteBlog;
