// src/pages/Blog/Blog.js
import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { db } from '../../firebaseConfig'; // Firebase configuration
import { collection, getDocs } from 'firebase/firestore';
import './Blog.css';
import { Subscribe, BlogCard } from '../../components';

const Blog = () => {
  const [blogs, setBlogs] = useState([]);

  // Fetch blogs from Firebase Firestore
  useEffect(() => {
    const fetchBlogs = async () => {
      const blogsCollection = collection(db, 'blogs');
      const blogSnapshot = await getDocs(blogsCollection);
      const blogList = blogSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setBlogs(blogList);
    };

    fetchBlogs();
  }, []);

  return (
    <div>
      <Subscribe />

      <Container className="mt-5">
        <Row>
          {blogs.map((blog) => (
            <Col key={blog.id} xs={12} sm={6} md={4} lg={4} xl={3} className="mb-4">
              <BlogCard
                id={blog.id}
                title={blog.title}
                date={blog.date}
                excerpt={blog.excerpt || blog.content.substring(0, 100) + '...'}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default Blog;
