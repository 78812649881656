// firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

// Your Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBtzrL78swrmvE4wK8JoKF4yMYFmfzPOPk",
    authDomain: "milestone-logistics-5e067.firebaseapp.com",
    projectId: "milestone-logistics-5e067",
    storageBucket: "milestone-logistics-5e067.appspot.com",
    messagingSenderId: "644699026843",
    appId: "1:644699026843:web:d076ab77cb39e0a195dd3e",
    measurementId: "G-3VBKCL1TGB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

export { db, auth, storage};
