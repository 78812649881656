import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebaseConfig';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import './AdminPanel.css'
import { CreateBlog, DeleteBlog, ImageGallary, MailSend} from '../../components';
import { Button, Container } from 'react-bootstrap';

const AdminPanel = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate('/');
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [navigate]);

  // Logout function
  const handleLogout = async () => {
    try {
      await signOut(auth); // Firebase sign out
      navigate('/'); // Redirect to login page after logout
    } catch (error) {
      console.error('Failed to log out: ', error);
    }
  };

  return (
    <Container className="mt-5">
      <Button variant="danger" onClick={handleLogout} style={{ borderRadius: 0 }}>
        Logout
      </Button>
      <CreateBlog />
      <DeleteBlog />
      <MailSend />
      <ImageGallary />
    </Container>
  )
}

export default AdminPanel
