// src/components/CareerImage.js
import React from 'react';
import { Container } from 'react-bootstrap';
import './CareerImage.css';

const CareerImage = () => {
  return (
    <div className="career-image-section">
      <div className="overlay">
        <Container className="text-container">
          <h1 className="career-title">DISCOVER MORE THAN A JOB.</h1>
          <p className="career-subtitle">
            IF YOU ARE A HIGH-ACHIEVER SEEKING A FULFILLING ROLE AND READY TO CREATE A LASTING IMPACT, WE BELIEVE YOUR JOURNEY ENDS HERE. LET'S BUILD THE FUTURE TOGETHER.
          </p>

        </Container>
      </div>
    </div>
  );
};

export default CareerImage;
