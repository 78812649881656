import React from 'react'
import './Home.css'
import { CarouselsAbout, ServicesCards, VideoPlayer, BannerBlock, PanelAbout} from '../../components';

const Home = () => {
  return (
    <div className='only'>
      <CarouselsAbout />
      <BannerBlock />
      <VideoPlayer />
      <ServicesCards />
      <PanelAbout />
    </div>
  )
}

export default Home
