// src/components/CareerBenefits.js
import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './CareerBenefits.css';

const CareerBenefits = () => {
  return (
    <div className="career-benefits-section">
      <Container className="text-center">
        <h1 className="career-benefits-title">BENEFITS? NO DOUBT!</h1>
        <p className="career-benefits-subtitle">
          You will spend 40% of your life at work. That is a lot of time, which is why Barnhart understands the importance of balance, benefits, and better opportunities for you and your family.
        </p>
      </Container>

      <Container>
        <Row className="justify-content-center">
          <Col md={4} className="mb-4">
            <Card className="benefit-card h-100">
              <Card.Body>
                <Card.Title className="benefit-title">BENEFITS</Card.Title>
                <Card.Text>
                  401(k), family health plans, and even pet insurance, Barnhart has one of the best benefit programs in the industry.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col md={4} className="mb-4">
            <Card className="benefit-card h-100">
              <Card.Body>
                <Card.Title className="benefit-title">SUCCESS TRAINING</Card.Title>
                <Card.Text>
                  Continuous Improvement is a core value at Barnhart. We want to make sure you have the tools and training you need to forge a path of success and advancement in your career.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col md={4} className="mb-4">
            <Card className="benefit-card h-100">
              <Card.Body>
                <Card.Title className="benefit-title">PURPOSE</Card.Title>
                <Card.Text>
                  Our goal is to be profitable, but we are deeply committed to using that profit to meet the physical, spiritual, and mental needs of others.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CareerBenefits;
