// src/BlogOpenCard.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebaseConfig'; // Firebase configuration file
import './BlogOpenCard.css';

const BlogOpenCard = () => {
  const { id } = useParams(); // Get the blog ID from the URL parameters
  const [blogPost, setBlogPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  // Fetch the blog post from Firestore based on the ID from the URL
  useEffect(() => {
    const fetchBlogPost = async () => {
      try {
        const blogRef = doc(db, 'blogs', id); // Reference to the blog document
        const blogSnap = await getDoc(blogRef); // Fetch the document

        if (blogSnap.exists()) {
          setBlogPost(blogSnap.data()); // Set the blog data to state
        } else {
          setError('Blog post not found.');
        }
      } catch (err) {
        console.error('Error fetching blog:', err);
        setError('Failed to fetch the blog post.');
      } finally {
        setLoading(false);
      }
    };

    fetchBlogPost();
  }, [id]);

  if (loading) {
    return <p>Loading blog post...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  const cardStyle = {
    borderRadius: '0px',
  };

  return (
    <Container className="blog-open-card mt-5">
      {/* Row for the Image */}
      <Row className="justify-content-center">
        <Col md={10}>
          <Card.Img src={blogPost.imageUrl} alt={blogPost.title} className="img-fluid" />
        </Col>
      </Row>

      {/* Row for the Content */}
      <Row className="justify-content-center mb-4">
        <Col md={10}>
          <Card className="p-4 blog-content-card" style={cardStyle}>
            <Card.Body>
              <Row className="justify-content-center mb-4">
                <Col md={10}>
                  <h1 className="text-center blog-title">{blogPost.title}</h1>
                </Col>
              </Row>
              <div className="blog-content">
                {blogPost.content.split('\n').map((paragraph, index) => (
                  <p key={index} className="mb-4">
                    {paragraph}
                  </p>
                ))}
              </div>
              <p className="blog-meta text-muted">
                <span>{blogPost.date}</span> by <span>{blogPost.author}</span>
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default BlogOpenCard;
