// src/Subscribe.js
import React, { useState } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import emailjs from 'emailjs-com';
import './Subscribe.css';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../../firebaseConfig'; // Firebase Firestore config

const Subscribe = () => {
  const [email, setEmail] = useState('');

  const sendSubscription = (e) => {
    e.preventDefault();
  
    const templateParams = {
      email,
    };
  
    // Send email via EmailJS
    emailjs
      .send(
        'service_scxbb0w',
        'template_0h22nap',
        templateParams,
        'WtHt83vihguL2XErp'
      )
      .then(
        (response) => {
          console.log('SUCCESS!', response.status, response.text);
          alert('Subscription successful!');
        },
        (error) => {
          console.log('FAILED...', error);
          alert('Failed to send the subscription. Please try again later.');
        }
      );
  
    // Save the subscriber's email to Firestore
    const saveSubscriberToFirestore = async () => {
      try {
        await addDoc(collection(db, 'subscribers'), {
          email: email,
          subscribedAt: new Date(),
        });
        setEmail(''); // Clear input field
      } catch (error) {
        console.error('Error adding subscriber: ', error);
      }
    };
  
    saveSubscriberToFirestore();
  };
  

  return (
    <div className="subscribe-section">
      <Container>
        <Row className="justify-content-center text-center">
          <Col md={10}>
            <h1 className="subscribe-title">News Subscription</h1>
            <p className="subscribe-text">
              Our team preapared for you the best blog subscribtion and promotions
            </p>

            {/* Email Subscription Form */}
            <Form onSubmit={sendSubscription} className="subscribe-form">
              <Form.Group controlId="formEmail" className="d-flex justify-content-center">
                <Form.Control
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="subscribe-input"
                  style={{ borderRadius: 3 }}
                />
                <Button
                  variant="outline-warning"
                  type="submit"
                  className="subscribe-btn"
                  style={{ borderRadius: 3 }}
                >
                  Submit
                </Button>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Subscribe;
