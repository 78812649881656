import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';
import { db } from '../../firebaseConfig';
import { collection, addDoc } from 'firebase/firestore';
import emailjs from 'emailjs-com';
import './Contact.css';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (submitted) {
      const timer = setTimeout(() => {
        setSubmitted(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [submitted]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const docRef = await addDoc(collection(db, 'contacts'), {
        name: formData.name,
        email: formData.email,
        message: formData.message,
        timestamp: new Date(),
      });

      console.log('Document written with ID: ', docRef.id);

      emailjs.send(
        'service_fsc1sf6',
        'template_ulan8si',
        {
          name: formData.name,
          email: formData.email,
          message: formData.message,
        },
        'hXzdWueuVr7ui9W5X'
      ).then(
        (response) => {
          console.log('SUCCESS!', response.status, response.text);
        },
        (error) => {
          console.log('FAILED...', error);
        },
      );

      setSubmitted(true);
      setFormData({ name: '', email: '', message: '' });
    } catch (err) {
      console.error('Error adding document: ', err);
      setError(true);
    }
  };

  return (
    <div className="contact-form-container">
      <Container className="contact-form">
        <Row className="justify-content-md-center">
          <Col lg={5} md={6} sm={12} className="mb-4">
            <div className="company-info">
              <h2>Logistics Company</h2>
              <p>
                All the written content on this website has been thoughtfully generated using AI technology. Our goal is to provide clear and informative text that helps convey the essential information about our logistics services, creating a seamless and engaging reading experience.
              </p>
            </div>
          </Col>
          <Col lg={6} md={6} sm={12}>
            <h2>Contact Us</h2>

            {submitted && (
              <Alert variant="success" onClose={() => setSubmitted(false)} dismissible>
                Message sent successfully!
              </Alert>
            )}

            {error && (
              <Alert variant="danger" onClose={() => setError(false)} dismissible>
                An error occurred. Please try again.
              </Alert>
            )}

            <Form onSubmit={handleSubmit} className='contact-us-form'>
              <Form.Group controlId="formName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Enter your name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  className="rounded-3"
                />
              </Form.Group>

              <Form.Group controlId="formEmail" className="mt-3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Enter your email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className="rounded-3"
                />
              </Form.Group>

              <Form.Group controlId="formMessage" className="mt-3">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  name="message"
                  placeholder="Enter your message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  className="rounded-3"
                />
              </Form.Group>

              <Button
                variant="warning"
                type="submit"
                className="mt-4 w-100 rounded-3"
              >
                SEND
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Contact;
