import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Navbar, Footer, About, Blog, Contact, Career, PrivacyPolicy, Home, ScrollToTop, BlogOpenCard, BlogCard, AdminPanel, Login } from './components';
import './App.css';

function App() {
  return (
    <Router>
      <div className="main-content">
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog-open-card/:id" element={<BlogOpenCard />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/career" element={<Career />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/admin-panel" element={<AdminPanel />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
