import React from 'react'
import './Career.css'
import { CareerAbout, CareerBenefits, CareerImage, CareerLink } from '../../components'

const Career = () => {
  return (
    <div>
      <CareerImage />
      <CareerBenefits />
      <CareerLink />
      <CareerAbout />
    </div>
  )
}

export default Career
