
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Collapse, Navbar as BootstrapNavbar, Nav, Container } from 'react-bootstrap';
import './Navbar.css';

function Navbar() {
  const [open, setOpen] = useState(false);

  // Function to handle toggling the collapse state
  const toggleNavbar = () => setOpen(!open);

  // Function to close the navbar when a link is clicked
  const closeNavbar = () => setOpen(false);

  return (
    <BootstrapNavbar expand="lg" className="navbar-light sticky-top custom-navbar" style={{ padding: 0 }}>
      <Container fluid>
        <Link className="navbar-brand d-flex align-items-center" to="/">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/milestone-logistics-5e067.appspot.com/o/images%2FDALL·E%202024-10-25%2020.52.26%20-%20A%20professional%20and%20modern%20logo%20for%20a%20logistics%20company%20named%20'CargoWave'%20without%20any%20text.%20The%20design%20features%20a%20stylized%20truck%20integrated%20into%20the%20lo.webp?alt=media&token=80968036-8221-48cd-b04a-360240401235"
            alt="Milestone Group Logistics"
            className="logo-image"
          />
          <span className="navbar-text ms-3">CargoWave</span>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleNavbar}
          aria-controls="navbarNav"
          aria-expanded={open}
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <Collapse in={open}>
          <div id="navbarNav" className="navbar-collapse">
            <Nav className="ms-auto navUl">
              <Nav.Item>
                <Link className="nav-link" to="/" onClick={closeNavbar}>HOME</Link>
              </Nav.Item>
              <Nav.Item>
                <Link className="nav-link" to="/about" onClick={closeNavbar}>ABOUT US</Link>
              </Nav.Item>
              <Nav.Item>
                <Link className="nav-link" to="/blog" onClick={closeNavbar}>BLOG</Link>
              </Nav.Item>
              <Nav.Item>
                <Link className="nav-link" to="/career" onClick={closeNavbar}>CAREER</Link>
              </Nav.Item>
              <Nav.Item>
                <Link className="nav-link" to="/contact" onClick={closeNavbar}>CONTACT</Link>
              </Nav.Item>
              <Nav.Item>
                <Link className="nav-link" to="/login" onClick={closeNavbar}>LOGIN</Link>
              </Nav.Item>
            </Nav>
          </div>
        </Collapse>
      </Container>
    </BootstrapNavbar>
  );
}

export default Navbar;
