// src/components/Gallery.js
import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebaseConfig'; // Import your Firebase Firestore configuration
import './Gallery.css';

const Gallery = () => {
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null); // State to store clicked image
  const [isOpen, setIsOpen] = useState(false); // State to control lightbox visibility

  // Fetch images from Firebase Firestore
  useEffect(() => {
    const fetchImages = async () => {
      try {
        const galleryCollection = collection(db, 'gallery'); // Replace 'gallery' with your collection name
        const gallerySnapshot = await getDocs(galleryCollection);
        const galleryList = gallerySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setImages(galleryList);
      } catch (error) {
        console.error('Error fetching images from Firestore:', error);
      }
    };

    fetchImages();
  }, []);

  // Function to open lightbox
  const openLightbox = (imgSrc) => {
    setSelectedImage(imgSrc);
    setIsOpen(true);
  };

  // Function to close lightbox
  const closeLightbox = () => {
    setIsOpen(false);
    setSelectedImage(null);
  };

  // Function to distribute images evenly into 4 columns
  const distributeImages = (images) => {
    const columns = [[], [], [], []]; // 4 columns (arrays)
    images.forEach((image, index) => {
      columns[index % 4].push(image); // Distribute images across columns
    });
    return columns;
  };

  const columns = distributeImages(images);

  return (
    <div className="container">
      <div className="heading">
        <h2>Gallery</h2>
      </div>
      <div className="box">
        {columns.map((column, colIndex) => (
          <div className="dream" key={colIndex}>
            {column.map((image, imgIndex) => (
              <img
                key={imgIndex}
                src={image.src}
                alt={image.alt}
                onClick={() => openLightbox(image.src)}
                className="gallery-image"
              />
            ))}
          </div>
        ))}
      </div>
      {isOpen && (
        <div className="lightbox" onClick={closeLightbox}>
          <span className="close">&times;</span>
          <img className="lightbox-content" src={selectedImage} alt="Selected" />
        </div>
      )}
    </div>
  );
};

export default Gallery;
