// src/components/ImageGallary.js
import React, { useState, useEffect } from 'react';
import { Form, Button, Container, ProgressBar, Alert, Row, Col, Card } from 'react-bootstrap';
import { storage, db } from '../../firebaseConfig';
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';
import { collection, addDoc, getDocs, doc, deleteDoc } from 'firebase/firestore';
import './ImageGallary.css';

const ImageGallary = () => {
  const [image, setImage] = useState(null);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [uploading, setUploading] = useState(false);
  const [images, setImages] = useState([]);

  // Fetch images from Firestore
  useEffect(() => {
    const fetchImages = async () => {
      try {
        const galleryCollection = collection(db, 'gallery');
        const gallerySnapshot = await getDocs(galleryCollection);
        const galleryList = gallerySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setImages(galleryList);
      } catch (error) {
        console.error('Error fetching images from Firestore:', error);
      }
    };

    fetchImages();
  }, [success]);

  // Handle image file selection
  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  // Handle form submission for image upload
  const handleUpload = (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    setUploading(true);

    if (!image) {
      setError('Please select an image to upload.');
      setUploading(false);
      return;
    }

    const storageRef = ref(storage, `gallery/${image.name}`);
    const uploadTask = uploadBytesResumable(storageRef, image);

    // Monitor upload progress
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        setProgress(progress);
      },
      (error) => {
        console.error('Upload failed:', error);
        setError('Upload failed. Please try again.');
        setProgress(0);
        setUploading(false);
      },
      () => {
        // Get the download URL once upload is complete
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          saveImageToFirestore(downloadURL);
        });
      }
    );
  };

  // Save the uploaded image URL to Firestore
  const saveImageToFirestore = async (downloadURL) => {
    try {
      await addDoc(collection(db, 'gallery'), {
        src: downloadURL,
        alt: image.name,
        uploadedAt: new Date(),
      });
      setSuccess('Image uploaded successfully!');
      setProgress(0);
      setImage(null);
      setUploading(false);
    } catch (error) {
      console.error('Error saving image to Firestore:', error);
      setError('Failed to save image. Please try again.');
      setUploading(false);
    }
  };

  // Function to delete an image
  const handleDelete = async (imageId, imageUrl) => {
    try {
      // Delete the image file from Firebase Storage
      const imageRef = ref(storage, imageUrl);
      await deleteObject(imageRef);

      // Delete the image document from Firestore
      await deleteDoc(doc(db, 'gallery', imageId));

      setSuccess('Image deleted successfully!');
    } catch (error) {
      console.error('Error deleting image:', error);
      setError('Failed to delete image. Please try again.');
    }
  };

  // Automatically clear error and success messages after 2 seconds
  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError('');
      }, 2000);
      return () => clearTimeout(timer);
    }

    if (success) {
      const timer = setTimeout(() => {
        setSuccess('');
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [error, success]);

  return (
    <Container className="mt-5">
      <h2 className="mb-4">Upload Image to Gallery</h2>

      {error && <Alert variant="danger">{error}</Alert>}
      {success && <Alert variant="success">{success}</Alert>}

      <Form onSubmit={handleUpload}>
        <Form.Group controlId="formFile" className="mb-3">
          <Form.Label>Select an image</Form.Label>
          <Form.Control type="file" onChange={handleImageChange} accept="image/*" />
        </Form.Group>

        {progress > 0 && <ProgressBar now={progress} label={`${progress}%`} className="mb-3" />}

        <Button
          variant="warning"
          type="submit"
          style={{ borderRadius: 0, width: '100%' }}
          disabled={uploading}
        >
          {uploading ? 'Uploading...' : 'Upload Image'}
        </Button>
      </Form>

      <h3 className="mt-5">Gallery Images</h3>
      <Row className="mt-3">
        {images.map((image) => (
          <Col key={image.id} xs={12} sm={6} md={4} lg={3} className="mb-4">
            <Card>
              <Card.Img variant="top" src={image.src} alt={image.alt} />
              <Card.Body>
                <Button
                  variant="outline-danger"
                  style={{ borderRadius: 0, width: '100%' }}
                  onClick={() => handleDelete(image.id, image.src)}
                >
                  Delete
                </Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default ImageGallary;
